import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import AnimationWrapper from "../components/animationWrapper"
import Container from "../components/container"
import Header from "../components/header"
import ListingTilesSection from '../components/listingTilesSection'
import TextSection from "../components/textSection"
import Layout from "../components/layout"
// import Logo from '../images/logo-header.png'
// import Aplikuj from '../images/kariera/aplikuj.jpg'

import "./kariera.css"

const KarieraPage = ({data, location}) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!`;

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      {data.page.frontmatter.text_section_main.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_main} />
          </AnimationWrapper>
        </Container>
      }

      <section id="kariera-tiles-section" className="listing-tiles-section">
        <ListingTilesSection data={data.karieraTiles} subcategoryName="Aktualnie poszukujemy:" location="kariera" />
      </section>

      {data.page.frontmatter.text_section_bottom.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_bottom} />
          </AnimationWrapper>
        </Container>
      }

      {/* <AnimationWrapper>
        <Container className="kariera-logo-wrapper">
          <img src={Logo} alt="Czamaninek" />
        </Container>
      </AnimationWrapper> */}

      {/* <img src={Aplikuj} alt="Czamaninek" /> */}

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "kariera" }, type: { eq: "page" } }) {
      frontmatter{
        title
        meta_description
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_main{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
        text_section_bottom{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
      }
    }
    karieraTiles: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {frontmatter: {type: {eq: "kariera-listing"}}}
    ){
      nodes {
        frontmatter {
          title
          slug
          subtitle
          desc_short
        }
        id
        internal {
          contentFilePath
        }
      }
    }
  }
`

export default KarieraPage
