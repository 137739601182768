import * as React from "react"
// import PropTypes from "prop-types"
// import { GatsbyImage } from 'gatsby-plugin-image'

import AnimationWrapper from "./animationWrapper"
import CustomAniLink from "../components/customAniLink"
// import styled from "styled-components"

import "./listingTile.css"

const ListingTile = ({ listing, location }) => {

  const link = `/${location}/${listing.frontmatter.slug}`

  return (
    <AnimationWrapper className="listing-tile-wrapper" animationType="fadeInUp">
        <CustomAniLink Link={link} className="listing-tile">

          <div className="listing-info-wrapper">
            <div className="listing-name">{listing.frontmatter.title}</div>

            {listing.frontmatter.subtitle &&
              <div className="listing-subtitle section-subtitle">
                {listing.frontmatter.subtitle}
              </div>
            }

            {listing.frontmatter.desc_short &&
              <div className="listing-description">
                {listing.frontmatter.desc_short}
              </div>
            }
          </div>

        </CustomAniLink>
    </AnimationWrapper>
  )
}

export default ListingTile