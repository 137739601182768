import * as React from "react"
// import PropTypes from "prop-types"

// import { graphql, useStaticQuery } from 'gatsby'
// import { MDXRenderer } from 'gatsby-plugin-mdx'
import AnimationWrapper from "./animationWrapper"
import Container from "./container"
import ListingTile from "./listingTile"
import Title from "./title"

const ListingTilesSection = ( {data, location, subcategoryName} ) => {

    return (
      <Container>
        {subcategoryName &&
          <AnimationWrapper className="subcategory-name-wrapper" animationType="fadeInUp">
            <Title title={subcategoryName} className="subcategory-name" />
          </AnimationWrapper>
        }
        <div className={`${location}-tiles listing-tiles-section`}>
          {data.nodes.map((node) => (
            <ListingTile listing={node} location={location} />
          ))}
        </div>
      </Container>
    )
  }

export default ListingTilesSection